import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-global-news',
  templateUrl: './global-news.component.html',
  styleUrls: ['./global-news.component.scss']
})
export class GlobalNewsComponent implements OnInit {

  featureNewsDatas: any;
  featureLoaded: boolean;
  trendingNewsDatas: any;
  trendingLoaded: boolean;
  archivedLoaded: boolean;
  archivedNewsDatas: any;
  envSiteName:any;
  MetaTagLoadedNews: boolean;
  MetaTagDataNews: any;

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slideConfigFeatureNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  slideConfigTrendingNews = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "nextArrow": "<div class='fa fa-angle-right next-slide'></div>",
    "prevArrow": "<div class='fa fa-angle-left prev-slide'></div>",
    "dots": false,
    "infinite": true
  };
  constructor(private httpclientService: HttpclientService,private metaTagService: Meta,private titleService: Title,private canonical:CanonicalService) {
    this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.featuredNews();
    this.trendingNews();
    this.archivedNews();
    this.AllMetaTagFeatured();
  }

  featuredNews(): void {
    this.featureLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9FutureNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.featureNewsDatas = items;
          this.featureLoaded = true;
          console.log("news featureNewsDatas",this.featureNewsDatas)
          window.scrollTo(0,0);
        });
  }
  
  trendingNews(): void {
    this.trendingLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9TrendingNewsDetails?siteName='+this.envSiteName)
      .subscribe(
        items => {
          this.trendingNewsDatas = items;
          this.trendingLoaded = true;
          console.log("news trendingNewsDatas",this.trendingNewsDatas)
          window.scrollTo(0,0);
        });
  }
  archivedNews(): void{
    this.archivedLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/News/GetTop9ArchivedNewsDetails?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.archivedNewsDatas = items;
        this.archivedLoaded = true;
        console.log("News ArchivedNews Data",this.archivedNewsDatas);
      }
    )
  }
  AllMetaTagFeatured(): void {
    if(this.MetaTagLoadedNews!=true){
    this.MetaTagLoadedNews = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName=News')
    .subscribe(
      items => {
        this.MetaTagDataNews = items[0];
        this.MetaTagLoadedNews=true;
        console.log('this.MetaTagDataNews',this.MetaTagDataNews);
        
  
          //seo code for tags
          this.titleService.setTitle(this.MetaTagDataNews.PageTitle);
  
          this.canonical.CreateCanonicalLink();

          // this.metaTagService.updateTag(
          //   {name:'robots',content:'index,follow'},
          // );

          this.metaTagService.updateTag(
          { name: 'og:description', content: this.MetaTagDataNews.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:title', content: this.MetaTagDataNews.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:image', content: this.MetaTagDataNews.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'og:image:alt', content: this.MetaTagDataNews.PageTitle}
          );

          this.metaTagService.updateTag(
            { name: 'description', content: this.MetaTagDataNews.OgDescription}
            );
    
            this.metaTagService.updateTag(
              { name: 'title', content: this.MetaTagDataNews.PageTitle}
            );
            this.metaTagService.updateTag(
              { name: 'keywords', content: this.MetaTagDataNews.OgKeyWords}
            );
            this.metaTagService.updateTag(
              { name: 'image', content: this.MetaTagDataNews.OgImageURL}
            );
    
            this.metaTagService.updateTag(
              { name: 'image:alt', content: this.MetaTagDataNews.PageTitle}
            );

          this.metaTagService.updateTag(
            { name: 'twitter:description', content: this.MetaTagDataNews.OgDescription}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:title', content: this.MetaTagDataNews.PageTitle}
          );
          this.metaTagService.updateTag(
            { name: 'twitter:image', content: this.MetaTagDataNews.OgImageURL}
          );
          //seo code for tags end
        });
      }
  }

}
